import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import React, { ReactNode } from "react";

export default (props: {
  static?: boolean;
  title: string;
  children: ReactNode;
  className?: string;
}) => (
  <Disclosure as="div" className={props.className}>
    {({ open }) => (
      <>
        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
          <span>{props.title}</span>
          {!props.static && (
            <ChevronUpIcon
              className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-blue-500`}
            />
          )}
        </Disclosure.Button>
        <Disclosure.Panel
          static={props.static}
          className="px-4 pt-4 pb-2 space-y-2 text-sm text-gray-500"
        >
          {props.children}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);
