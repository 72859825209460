import { Router } from "@reach/router";
import React from "react";
import Convert from "../clientOnlyRoutes/Convert";
import Layout from "../components/Layout";
import PrivateRoute from "../components/PrivateRoute";

const App = () => {
  return (
    <Layout>
      <Router basepath="/app">
        <PrivateRoute path="/" component={Convert} />
      </Router>
    </Layout>
  );
};
export default App;
