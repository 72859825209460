import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);
  return authenticated ? <Component {...rest} /> : null;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
