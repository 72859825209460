import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import Disclosure from "../components/Disclosure";
import { Input, Select } from "../components/InputFields";
import Map from "../components/Map";
import { addMessage } from "../state/reducers/appReducer";
import { fetchBaseOptions } from "../utils/fetch";

interface IOption {
  label: string;
  value: string;
  help: string;
  links: string[];
}

const options: IOption[] = [
  // {
  //   label: "DHDN2001GK",
  //   value: "DHDN2001GK",
  //   links: ["https://epsg.io/31467"],
  // },
  // {
  //   label: "ETRS89 Austria Lambert",
  //   value: "ETRS89AustriaLambert",
  //   links: ["https://epsg.io/3416"],
  // },
  // {
  //   label: "ETRS89 UTM",
  //   value: "ETRS89UTM",
  //   links: ["https://epsg.io/25832"],
  // },
  // {
  //   label: "MGI Austria GKM28",
  //   value: "MGIAustriaGKM28",
  //   links: ["https://epsg.io/31257"],
  // },
  // {
  //   label: "MGI Austria GKM31",
  //   value: "MGIAustriaGKM31",
  //   links: ["https://epsg.io/31258"],
  // },
  // {
  //   label: "MGI Austria GKM34",
  //   value: "MGIAustriaGKM34",
  //   links: ["https://epsg.io/31259"],
  // },
  // {
  //   label: "MGI Austria Lambert",
  //   value: "MGIAustriaLambert",
  //   links: ["https://epsg.io/31287"],
  // },
  // {
  //   label: "MGI Austria M28",
  //   value: "MGIAustriaM28",
  //   links: ["https://epsg.io/31284"],
  // },
  // {
  //   label: "MGI Austria M31",
  //   value: "MGIAustriaM31",
  //   links: ["https://epsg.io/31285"],
  // },
  // {
  //   label: "MGI Austria M34",
  //   value: "MGIAustriaM34",
  //   links: ["https://epsg.io/31286"],
  // },
  // {
  //   label: "NAD83 Alabama East",
  //   value: "NAD83AlabamaEast",
  //   links: ["https://epsg.io/6355"],
  // },
  // {
  //   label: "NAD83 Alabama West",
  //   value: "NAD83AlabamaWest",
  //   links: ["https://epsg.io/6356"],
  // },
  // {
  //   label: "NAD83 California Albers",
  //   value: "NAD83CaliforniaAlbers",
  //   links: ["https://epsg.io/6414"],
  // },
  // {
  //   label: "OSGB36 National Grid",
  //   value: "OSGB36NationalGrid",
  //   links: ["https://epsg.io/27700"],
  // },
  // {
  //   label: "RGF93CC",
  //   value: "RGF93CC",
  //   links: ["https://epsg.io/3950"],
  // },
  // {
  //   label: "RGF93 France Lambert",
  //   value: "RGF93FranceLambert",
  //   links: ["https://epsg.io/2154"],
  // },
  {
    label: "UTM",
    value: "UTM",
    help: "The file needs to contain two columns: northing and easting",
    links: ["https://epsg.io/32632"],
  },
  // {
  //   label: "WebMercator",
  //   value: "WebMercator",
  //   links: ["https://epsg.io/3857"],
  // },
];

const outputOptions = [
  {
    label: "CSV",
    value: "csv",
    help: "Comma-separated values",
    links: [],
  },
  {
    label: "KML",
    value: "kml",
    help: `KML is a file format used to display geographic data in an Earth browser such as Google Earth.
      You can optionally include a name and description column for each set of coordinates`,
    links: [],
  },
];

const optionValidator = (opts: IOption[]) =>
  yup
    .string()
    .required("Required")
    .oneOf(opts.map(o => o.value));

const separatorValidator = yup.string().length(1);

const FileConvertSchema = yup.object().shape({
  inputFormat: optionValidator(options),
  outputFormat: optionValidator(outputOptions),
  separator: separatorValidator,
  comment: separatorValidator,
  trimLeadingSpace: yup.string().oneOf(["true", "false"]),
  northern: yup.string().oneOf(["true", "false"]),
  zone: yup.number(),
});

const helpText = (option: IOption) => {
  const links = option.links.map(h => (
    <a key={h} target="_blank" rel="noreferrer nofollow noopener" href={h}>
      {h}
    </a>
  ));
  return (
    <p className="mt-2 text-xs cursor-help">
      <QuestionMarkCircleIcon className="inline-block w-4 h-4 mr-1" />
      {option.label} is a geographic Coordinate Reference System similar to {links}
    </p>
  );
};

/**
 * Prompts the user to save the file with the converted results
 * @param content file content
 * @param name file name
 * @param type content type
 * @param ext extension of the saved file
 */
const saveFile = (content: string, name: string, type: string, ext: string) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = window.URL.createObjectURL(new Blob([content], { type: type }));
  a.download = `${new Date().toISOString()}-results-${name}.${ext}`;
  a.target = "_blank";
  a.click();
  a.remove();
};

const saveCSV = (content: string, name: string) =>
  saveFile(content, name, "application/csv", "csv");

const saveKML = (content: string, name: string) =>
  saveFile(content, name, "application/vnd.google-earth.kml+xml", "kml");

interface IInitialValues {
  inputFormat: "UTM";
  outputFormat: "csv" | "kml";
  file: any;
  separator: string;
  comment: string;
  trimLeadingSpace: string;
  northern: string;
  zone: number;
}

/**
 * Parses the CSV results from the API
 * @param csv data in rows of lat,lon,h format
 * @returns number[][]
 */
const parseCSV = (csv: string) => {
  return csv
    .split("\n")
    .map(r => r.split(",").map(s => parseFloat(s)))
    .slice(1);
};

const Convert = () => {
  const dispatch = useDispatch();
  const initialValues: IInitialValues = {
    inputFormat: "UTM",
    outputFormat: "csv",
    file: null,
    separator: ",",
    comment: "",
    trimLeadingSpace: "false",
    northern: "true",
    zone: 0,
  };
  const [data, setData] = useState([]);
  return (
    <div className={data.length > 0 && "grid grid-cols-3"}>
      <Formik
        initialValues={initialValues}
        validationSchema={FileConvertSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (values.file === null || !values.file.name.endsWith(".csv")) return;

          const formData = new FormData();
          formData.append("inputFormat", values.inputFormat);
          formData.append("outputFormat", values.outputFormat);
          formData.append("separator", values.separator);
          formData.append("comment", values.comment);
          formData.append("trimLeadingSpace", String(values.trimLeadingSpace));
          formData.append("file", values.file);
          formData.append("northern", String(values.northern));
          formData.append("zone", String(values.zone));

          fetch("/api/file/convert", {
            ...fetchBaseOptions,
            headers: {},
            body: formData,
          })
            .then(async res => {
              if (res.ok) {
                const j = await res.json();
                if (values.outputFormat === "csv") {
                  saveCSV(j, values.file.name.replace(".csv", ""));
                  setData(parseCSV(j));
                } else if (values.outputFormat === "kml") {
                  saveKML(j, values.file.name.replace(".csv", ""));
                }
              } else {
                const j: IAPIResponse = await res.json();
                dispatch(addMessage(j));
              }
            })
            .catch(err => console.error(err))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid, values, setFieldValue }) => {
          const inputFormat = options.filter(o => o.value === values.inputFormat);
          const outputFormat = outputOptions.filter(o => o.value === values.outputFormat);
          const selected = inputFormat.length !== 0 && inputFormat[0];
          const outputSelected = outputFormat.length !== 0 && outputFormat[0];
          return (
            <Form
              className={classNames(
                "p-4 space-y-2 rounded shadow-xl",
                data.length > 0 ? "col-span-1" : "max-w-xl mx-auto",
              )}
            >
              <Disclosure static={true} title="Base options">
                <div>
                  <Field
                    required={true}
                    name="inputFormat"
                    label="Input format"
                    component={Select}
                    options={options}
                  />
                  {selected && helpText(selected)}
                </div>
                <div className="mt-4">
                  <Field
                    required={true}
                    name="outputFormat"
                    label="Output format"
                    component={Select}
                    options={outputOptions}
                  />
                  {outputSelected && (
                    <p className="mt-2 text-xs cursor-help">{outputSelected.help}</p>
                  )}
                </div>
                <div className="flex flex-col mt-4">
                  <label className="block" htmlFor="file">
                    File
                  </label>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    accept=".csv"
                    onChange={event => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                  />
                  {selected && (
                    <p className="px-4 py-1 mt-2 text-sm text-gray-900 bg-yellow-100 rounded">
                      {selected.help}
                    </p>
                  )}
                </div>
              </Disclosure>

              {selected.value === "UTM" && (
                <Disclosure static={true} title="UTM options">
                  <div>
                    <Field type="number" name="zone" label="Zone" component={Input} />
                    <div id="radio-group">Northern</div>
                    <div className="space-x-4" role="group" aria-labelledby="radio-group">
                      <label className="space-x-2">
                        <Field type="radio" name="northern" value="true" />
                        <span>True</span>
                      </label>
                      <label className="space-x-2">
                        <Field type="radio" name="northern" value="false" />
                        <span>False</span>
                      </label>
                    </div>
                  </div>
                </Disclosure>
              )}
              <Disclosure title="Advanced options">
                <div>
                  <Field
                    type="text"
                    name="separator"
                    label="CSV separator character"
                    component={Input}
                  />
                  <Field
                    type="text"
                    name="comment"
                    label="CSV comment character"
                    component={Input}
                  />
                  <div id="radio-group">Trim leading space</div>
                  <div className="space-x-4" role="group" aria-labelledby="radio-group">
                    <label className="space-x-2">
                      <Field type="radio" name="trimLeadingSpace" value="true" />
                      <span>True</span>
                    </label>
                    <label className="space-x-2">
                      <Field type="radio" name="trimLeadingSpace" value="false" />
                      <span>False</span>
                    </label>
                  </div>
                </div>
              </Disclosure>
              <button
                className="w-full btn btn-primary"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
      {data.length > 0 && <Map className={"col-span-2"} data={data} />}
    </div>
  );
};

export default Convert;
