import classNames from "classnames";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import React, { useEffect, useRef } from "react";

export default (props: { className: string; data: number[][] }) => {
  const ref = useRef(null);
  useEffect(() => {
    const map = new maplibregl.Map({
      container: ref.current,
      style: "https://api.maptiler.com/maps/streets/style.json?key=NFn12nA2GoY7HScO7M7S", // stylesheet location
      center: [props.data[0][1], props.data[0][0]], // starting position [lng, lat]
      zoom: 7, // starting zoom
    });
    props.data.forEach(coords => {
      if (!isNaN(coords[0]) && !isNaN(coords[1])) {
        new maplibregl.Marker().setLngLat([coords[1], coords[0]]).addTo(map);
      }
    });
    return () => map.remove();
  }, [props.data]);
  return <div className={classNames(props.className, "w-full h-full border")} ref={ref}></div>;
};
